import fetchState from './globals/fetch_state'
import fetch from './globals/fetch'
import auth from './globals/auth'

export function nuxtPolyfills(opts) {
  return {
    install(app, options = opts) {
      const axios = app.config.globalProperties.$axios
      const store = app.config.globalProperties.$store
      const router = app.config.globalProperties.$router

      app.config.globalProperties.$fetchState = fetchState()
      app.config.globalProperties.$fetch = fetch({ app }, app.config.globalProperties.$fetchState)

      app.config.globalProperties.$auth = store.$auth = auth({ app, axios, store, router, i18n: options })
    },
  }
}
