import { setCookie } from '@/utils/setCookie'
import Cookies from 'js-cookie'

const CLIENT_ID = 3

export default function ({ axios, store, router, i18n }) {
  const auth = {
    user: null,

    async loginWith(strategy, { data: { username, password } }) {
      if (strategy !== 'local') {
        throw Error(`auth.js strategy ${strategy} not supported`)
      }

      const { data } = await axios({
        method: 'POST',
        url: 'oauth/token',
        showErrorModal: false,
        data: {
          client_id: CLIENT_ID,
          grant_type: 'password',
          username,
          password,
        },
      })

      await store.dispatch('user/setToken', data)

      setCookie('token', btoa(JSON.stringify(data)), store.state.session.country, { expires: data.expires_in / 86_400 })

      await this.fetchUser()
    },

    async logout() {
      await axios({
        method: 'POST',
        url: 'auth/logout',
      })

      Cookies.remove('token')

      this.user = null
    },

    async fetchUser() {
      const { data } = await axios({
        method: 'GET',
        url: 'profile',
      })

      this.user = {
        id: data.data.id,
        ...data.data.attributes,
      }
    },
  }

  // Add access_token to request header
  axios.interceptors.request.use((request) => {
    // Only send access token to the Bistroo API (and not to third party services like Google)
    const bistrooApi = `${import.meta.env.VITE_BACKEND_URL}${import.meta.env.VITE_API_PATH}`
    const toBistrooApi = request.baseURL.includes(bistrooApi)

    if (store.getters['user/loggedIn'] && toBistrooApi) {
      const token = store.getters['user/token'].access_token
      request.headers['AUTHORIZATION'] = `Bearer ${token}`
    }

    return request
  })

  // Refresh access_token on 401
  axios.interceptors.response.use(
    // success handler
    (response) => response,
    // error handler
    async (error) => {
      const request = error.config

      if (error.response && error.response.status === 401) {
        // Check if the failing request is a refresh_token call
        // to prevent a loop. If it is, redirect to the login page.
        if (
          request.data &&
          JSON.parse(request.data).grant_type === 'refresh_token'
        ) {
          auth.user = null
          store.dispatch('user/setToken', null)
          router.push({ name: `login___${i18n.global.locale.value}` })
        }

        const config = {
          client_id: CLIENT_ID,
          grant_type: 'refresh_token',
          refresh_token: store.getters['user/token'].refresh_token,
        }

        const { data } = await axios.post('oauth/token', config)

        await store.dispatch('user/setToken', data)

        setCookie('token', btoa(JSON.stringify(data)), store.state.session.country, { expires: data.expires_in / 86_400 })

        // Retry the original request
        return axios(request)
      }

      throw error
    }
  )

  return auth
}
